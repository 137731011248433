<template>
    <article id="wrap">
      <AppHeader theme="white">すてきなせかいとは</AppHeader>
      <section class="contents">
        <div class="contentInner pb_80">
            <div class="about">
                <div class="about-banner">
                    <div class="image">
                        <img class="tutorial-image custom-image" src="/app/img/sutekinasekai_tutorial_1.7200c1b6.png" alt="">
                    </div>
                    <h2>What's SUTEKI NA SEKAI？</h2>
                </div>
                <div class="about-head">
                    <p> 「すてきなせかい」とは、<br> あなたにとってとくべつな人が<br> そばに感じるしあわせなせかい。 </p>
                    <p> そんなせかいで<br> 今日あったできごと、なにげない会話を<br> たのしみましょう。 </p>
                </div>
                <div class="about-desc">
                    <h3>すてきなせかいとは？</h3>
                    <p> バズらないせかい。<br> 課金型SNSです。<br> あなたのとくべつな人となかまとともに<br> すてきなせかいをつくりましょう！ 
                    </p>
                </div>
                <div class="about-desc">
                    <h3>とくべつな人とは？</h3>
                    <p> あなたがおうえんするとくべつな人のこと<br> すてきなせかいではその人のことを<br> 「マスター」といいます。<br> あなたの「マスター」とともに<br> すてきなせかいへいってみよう！ 
                    </p>
                </div>
            </div>  
        </div><!--.contentInner-->
      </section><!--.contents-->
  
      <AppFooter fixed theme=""></AppFooter>
  
    </article>
  </template>
  
  <script>
  import AppHeader from '@/components/AppHeader';
  import AppFooter from '@/components/AppFooter';
  export default {
    name: 'TUTORIAL',
    components: {AppHeader, AppFooter},
  }
  </script>

  <style>
    :root {
        --font-jp: "游ゴシック体","YuGothic","游ゴシック Medium","Yu Gothic Medium","游ゴシック","Yu Gothic",sans-serif;
        --font-min: "Hiragino Mincho ProN","Yu Mincho",serif;
        --font-en: montserrat,sans-serif;
        --fs: clamp(0.9rem,0.614rem + 0.68vw,1.225rem);
        --color-bk: #262626;
        --color-wh: #fff;
        --color-gy: #ccc;
        --color-wgy: #eee;
        --color-bgy: #484848;
    }
        .about {
        padding: 10px 20px;
    }
    .about-banner .image {
        width: 200px;
        margin: auto;
    }
    .custom-image, picture {
        max-width: 100%;
        display: block;
        /* ここに追加のスタイルを定義 */
    }
    img {
        border-style: none;
    }
    .about-banner h2 {
        z-index: 1;
        display: block;
        position: relative;
        padding: 20px;
        font-family: var(--font-en);
        text-align: center;
        letter-spacing: .05em;
        text-indent: 0.05em;
    }
    .about-banner h2:before {
        z-index: 2;
        content: "";
        position: absolute;
        bottom: 10px;
        left: 50%;
        width: 80px;
        height: 1px;
        background: var(--color-bk);
        transform: translate(-50%);
    }
    .about-banner h2 {
        z-index: 1;
        display: block;
        position: relative;
        padding: 20px;
        font-family: var(--font-en);
        text-align: center;
        letter-spacing: .05em;
        text-indent: 0.05em;
    }
    h2 {
        font-size: 1.5em;
        font-weight: bold;
    }
    .about-head {
        padding: 20px 0 0;
    }
    .about-head p {
        padding: 15px 0;
        font-family: var(--font-min);
        /* font-size: calc(var(--fs)*); */
        font-weight: 500;
        text-align: center;
        line-height: 2.2;
    }
    .about-desc {
        padding-top: 30px;
    }
    .about-desc h3 {
        padding: 15px;
        background: var(--color-bgy);
        color: var(--color-wgy);
        line-height: 1;
        text-align: center;
    }
    .about-desc p {
        margin-top: 20px;
        padding: 30px;
        text-align: center;
        line-height: 2;
        background: var(--color-wgy);
        border-radius: 6px;
    }.about-desc h3 {
        padding: 15px;
        background: var(--color-bgy);
        color: var(--color-wgy);
        line-height: 1;
        text-align: center;
    }
</style>
  